<template>
  <Auth>
    <div class="row justify-content-center my-5 my-lg-auto">
      <div class="col-sm-8 col-md-10 col-xl-8">
        <form action="#" @submit.prevent="handleSubmit">
          <h1 class="display-3 mt-0 mb-3">{{ $t("page.forgot-password") }}</h1>

          <div class="card">
            <div class="card-body p-3">
              <b-alert
                variant="danger"
                class="mt-3"
                dismissible
                :show="Boolean(error)"
                >{{ error }}</b-alert
              >
              <div class="form-group mb-3">
                <label for="admin-login">{{ $t("form.login-email") }}</label>
                <input
                  class="form-control"
                  v-model="form.email"
                  :placeholder="$t('form.login-email-placeholder')"
                  :class="{ 'is-invalid': submitted && $v.form.email.$error }"
                />
                <div
                  v-if="submitted && $v.form.email.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.form.email.required">{{
                    $t("form.login-email-req")
                  }}</span>
                </div>
              </div>
              <div class="form-group mb-0 text-right form-note">
                <router-link to="/login">{{ $t("form.login") }}</router-link>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <button
              class="btn btn-primary btn-block w-100"
              type="submit"
              :disabled="loading"
            >
              {{ $t("btn.reset-pass-email-link") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </Auth>
</template>

<script>
import axios from "@/plugins/axios";
import Auth from "@/views/layouts/auth";
import { required, email } from "vuelidate/lib/validators";

export default {
  metaInfo() {
    return {
      title: this.$t("app.title") + " | " + this.$t("page.forgot-password"),
    };
  },
  components: {
    Auth,
  },
  data() {
    return {
      loading: false,
      error: "",
      form: {
        email: "",
      },
      submitted: false,
    };
  },
  validations: {
    form: {
      email: {
        // email,
        required,
      },
    },
  },
  methods: {
    async handleSubmit() {
      this.submitted = true;

      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
        this.error = "";

        // post
        // /api/reset-password/get-code
        // phone

        axios({
          method: "post",
          url: this.$urls.URL_GET_CODE,
          data: {
            phone: this.form.email,
          },
          headers: {
            lang: this.lang,
          },
        })
          .then((response) => {
            console.log("Restore pass, get-code, response: ", response);
          })
          .catch((error) => {
            this.error = this.$t("msg.error");

            if (error.response?.data?.message) {
              this.error = `[${error.response.status}] ${error.response.data.message}`;
            }
          });

        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
